div,
ol,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-image: url("./images/pix.png");
}

.App {
  text-align: center;
  display: flex;
}
